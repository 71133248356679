import React from 'react';
import IdCardList from '../components/IdCardList'

const IdCard = () => {
  return (
    <div>
      <h1 className='display-5'>ID Card Replacement / Yearbook Payment</h1>
      <hr></hr>
      <IdCardList />
    </div>
  );
};

export default IdCard;
